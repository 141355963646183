import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Markdown from 'markdown-to-jsx';
import Link from 'components/atoms/Link';
import { Ul, Ol, Li } from 'components/atoms/List';
import Table from 'components/atoms/Table/Table';
import Td from 'components/atoms/Table/Td';
import Tr from 'components/atoms/Table/Tr';
import Thr from 'components/atoms/Table/Thr';

const Wrapper = styled.div`
  word-wrap: break-word;
`;

const TextFormatter = ({ className, text }) => (
  <Wrapper className={className}>
    <Markdown
      children={text}
      options={{
        overrides: {
          a: {
            component: Link,
            props: {
              transformLocalHrefToRouterLink: true,
            },
          },
          li: Li,
          ol: Ol,
          ul: Ul,
          table: Table,
          tr: Tr,
          td: Td,
          th: Td,
        },
        createElement(type, props, ...children) {
          const c = children.length === 0 ? null : children.length === 1 ? children[0] : children;

          if (type === 'thead') {
            return React.Children.map(c, (child) => <Thr>{child.props.children}</Thr>);
          }

          if (type === 'tbody') {
            return c;
          }

          return React.createElement(type, props, c);
        },
      }}
    />
  </Wrapper>
);

TextFormatter.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default TextFormatter;
